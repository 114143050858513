export const DEFAULT_AVATAR_URL =
    "https://api.dicebear.com/8.x/initials/svg?backgroundType=gradientLinear&backgroundRotation=0,360&seed=";

export const PAGINATION_LIMIT = 10;

export const COMPANIES = [
    {
        name: "Asana",
        logo: "/assets/company-01.svg",
    },
    {
        name: "Tidal",
        logo: "/assets/company-02.svg",
    },
    {
        name: "Innovaccer",
        logo: "/assets/company-03.svg",
    },
    {
        name: "Linear",
        logo: "/assets/company-04.svg",
    },
    {
        name: "Raycast",
        logo: "/assets/company-05.svg",
    },
    {
        name: "Labelbox",
        logo: "/assets/company-06.svg",
    },
] as const;

interface BulletPoint {
    point: string;
}

interface Feature {
    title: string;
    description: string;
    action: string;
    bulletPoint: BulletPoint[];
    btnText: string;
}

export const FEATURES: Feature[] = [
    {
        title: "Global Search",
        description: "Use search from anywhere, front or backend.",
        action: "/features",
        bulletPoint: [
            { point: "Frontend and backend search" },
            { point: "Accessibility ready" },
            { point: "Real-time filtering for instant results" },
            { point: "Lightning fast search" },
            { point: "Intuitive UI" },
        ],
        btnText: "Save 90+ Hours Annually",
    },
    {
        title: "User Search",
        description: "Manage user info directly from WP Spotlight.",
        action: "/user-management",
        bulletPoint: [
            { point: "Search users by role" },
            { point: "Search users directly by email" },
            { point: "User profile access from search" },
            { point: "User role switching (upcoming)" },
            { point: "Delete users from search (upcoming)" },
        ],
        btnText: "Save 40+ Hours Annually",
    },
    {
        title: "Plugin Control",
        description: "Update plugins from search",
        action: "/plugin-management",
        bulletPoint: [
            { point: "Access plugin repository from search" },
            { point: "View plugin org page from search" },
            { point: "Manage all plugin actions from search" },
            { point: "Deactivate plugins from search" },
            { point: "Delete plugins from search" },
        ],
        btnText: "Save 60+ Hours Annually",
    },
    {
        title: "Theme Control",
        description: "Manage themes from search",
        action: "/plugin-management",
        bulletPoint: [
            { point: "Access theme repository from search" },
            { point: "Switch themes from search" },
            { point: "Delete themes from search" },
            { point: "Update themes from search" },
            { point: "Save time on theme management" },
        ],
        btnText: "Save 25+ Hours Annually",
    },
    {
        title: "Media Actions",
        description: "Manage media in search",
        action: "/media-management",
        bulletPoint: [
            { point: "Insert media into posts from search" },
            { point: "Edit media from search" },
            { point: "Upload media into search" },
            { point: "Delete media from search" },
            { point: "Download media from search" },
        ],
        btnText: "Save 55+ Hours Annually",
    },
    {
        title: "Multisite Actions",
        description: "Manage multisite network from search",
        action: "/multisite-management",
        bulletPoint: [
            { point: "Automatic detection of multisite" },
            { point: "Seamless and fast site switching" },
            { point: "Full sub-site list on search" },
            { point: "Time Instant network admin access" },
            { point: "Save time on multisite management" },
        ],
        btnText: "Save 100+ Hours Annually",
    },
];

export const ALLFEATURES = [
    {
        title: " User Management with Role-based Access",
        description:
            "Quickly access and edit user details without navigating through multiple screens.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Search by Role: View users by role (Admin, Author, Subscriber) instantly.",
            },
            {
                point: "Direct Email Search: Type an email to see all roles for that user.",
            },
            {
                point: "Edit Profiles: Jump directly to user profiles for quick edits.",
            },
        ],
    },
    {
        title: "Streamlined Plugin Management with WP-Admin Navigation",
        description: "Manage your plugins without leaving WP Spotlight.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Find & Install: Search and install plugins fast directly from the WordPress repository.",
            },
            {
                point: "One-Click Updates: Update plugins with one-click operations for productivity.",
            },
            {
                point: "Instant Plugin Access: Instantly search and access all plugin menus present on your WordPress dashboard from anywhere",
            },
            {
                point: "Activate/Deactivate: Easily toggle plugins on or off directly from search",
            },
            {
                point: "Quick Plugin Search: Search by plugin name to view its settings without navigating away for better WordPress workflow Optimization.",
            },
        ],
    },
    {
        title: "Instant Site Navigation for Theme Management",
        description: "Easily find, activate, and remove themes in seconds.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Access Repository: Makes searching and installing themes fast and effortless",
            },
            {
                point: "Quick Activation: Doubles as an efficiency tool plugin for quick theme management.",
            },
            {
                point: "Safe Deletion: Remove any theme except the currently active one.",
            },
        ],
    },
    {
        title: "CPT Search and Content Management",
        description:
            "Access all your posts and pages without extra clicks. WP Spotlight lets you view CPT Search results and manage content easily.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "View All Post Types: See every post type on your site in one list, with custom post types included.",
            },
            {
                point: "Quick Actions: Edit, view, or delete posts directly from WP Spotlight as part of its Admin Command Search capabilities.",
            },
        ],
    },
    {
        title: "Create Content Instantly",
        description:
            "Skip the usual steps and get right to creating with WP Spotlight’s direct content creation shortcuts. Select the “Create” option, choose your post type, and start adding new content instantly.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Choose Post Type: Select from your site’s post types and jump directly to the new post editor, supported by Global Site Search.",
            },
            {
                point: "One-Step Creation: Save time with Custom Commands Plugin by choosing a post type and jumping directly to the editor.",
            },
        ],
    },
    {
        title: "Simple Media Management",
        description: "Manage all your media from a single search bar.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Find Files Fast: Locate specific files and access editing options.",
            },
            {
                point: "Upload, Delete, Download: Add, remove, or download media in seconds.",
            },
        ],
    },
    {
        title: "Personalized Key Binds",
        description: "Customize your workflow by setting shortcuts for any action or search.",
        url: "http://surl.li/rnqyxo",
        bulletPoint: [
            {
                point: "Custom Shortcuts: Assign key binds for all WP Spotlight features. ",
            },
            {
                point: "Quick Access: Start searches or actions with a single keystroke.",
            },
        ],
    },
] as const;

export const REVIEWS = [
    {
        name: "Michael Smith",
        username: "@michaelsmith",
        avatar: "https://randomuser.me/api/portraits/men/1.jpg",
        rating: 5,
        review: "This tool is a lifesaver! Managing and tracking my links has never been easier. A must-have for anyone dealing with numerous links.",
    },
    {
        name: "Emily Johnson",
        username: "@emilyjohnson",
        avatar: "https://randomuser.me/api/portraits/women/1.jpg",
        rating: 4,
        review: "Very useful app! It has streamlined my workflow considerably. A few minor bugs, but overall a great experience.",
    },
    {
        name: "Daniel Williams",
        username: "@danielwilliams",
        avatar: "https://randomuser.me/api/portraits/men/2.jpg",
        rating: 5,
        review: "I've been using this app daily for months. The insights and analytics it provides are invaluable. Highly recommend it!",
    },
    {
        name: "Sophia Brown",
        username: "@sophiabrown",
        avatar: "https://randomuser.me/api/portraits/women/2.jpg",
        rating: 4,
        review: "This app is fantastic! It offers everything I need to manage my links efficiently.",
    },
    {
        name: "James Taylor",
        username: "@jamestaylor",
        avatar: "https://randomuser.me/api/portraits/men/3.jpg",
        rating: 5,
        review: "Absolutely love this app! It's intuitive and feature-rich. Has significantly improved how I manage and track links.",
    },
    {
        name: "Olivia Martinez",
        username: "@oliviamartinez",
        avatar: "https://randomuser.me/api/portraits/women/3.jpg",
        rating: 4,
        review: "Great app with a lot of potential. It has already saved me a lot of time. Looking forward to future updates and improvements.",
    },
    {
        name: "William Garcia",
        username: "@williamgarcia",
        avatar: "https://randomuser.me/api/portraits/men/4.jpg",
        rating: 5,
        review: "This app is a game-changer for link management. It's easy to use, extremely powerful and highly recommended!",
    },
    {
        name: "Mia Rodriguez",
        username: "@miarodriguez",
        avatar: "https://randomuser.me/api/portraits/women/4.jpg",
        rating: 4,
        review: "I've tried several link management tools, but this one stands out. It's simple, effective.",
    },
    {
        name: "Henry Lee",
        username: "@henrylee",
        avatar: "https://randomuser.me/api/portraits/men/5.jpg",
        rating: 5,
        review: "This app has transformed my workflow. Managing and analyzing links is now a breeze. I can't imagine working without it.",
    },
] as const;

import { cn } from "@/utils";
import React from "react";

interface Props {
    className?: string;
    children: React.ReactNode;
}

const MaxWidthWrapper = ({ className, children }: Props) => {
    return (
        <section
            className={cn(
                "jlt-blog-page-wrapper jlt-h-full jlt-mx-auto jlt-w-full jlt-max-w-full md:jlt-max-w-screen-xl jlt-px-4 md:jlt-px-12 lg:jlt-px-20",
                className
            )}
        >
            {children}
        </section>
    );
};

export default MaxWidthWrapper;

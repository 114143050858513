export const PLANS = [
    {
        name: "Starter",
        info: "For Freelancers, Solopreneurs",
        price: {
            monthly: 0,
            yearly: 0,
        },
        features: [
            { text: "Backend Access",tooltip: "Access WP Spotlight from only the backend."  },
            { text: "Manager users from search", tooltip: "Quickly search and manage user profiles directly from search" },
            { text: "Manage plugins from search",tooltip: "Activate, deactivate, and update plugins without leaving search" },
            { text: "Manage content from search", tooltip: "Find and edit posts, pages, and custom content instantly." },
            { text: "Manage media from search", tooltip: "Upload, edit, and insert media files straight from search." },
            { text: "Multisite switching", tooltip: "Easily switch between sites in multisite setups." },
            { text: "Custom keyboard shortcuts ", tooltip: "Assign custom key binds with up to 4 key combinations." },
        ],
        btn: {
            text: "Start for free",
            href: "/auth/sign-up?plan=free",
            variant: "default",
        },
    },
    {
        name: "Business",
        info: "For small businesses and agencies",
        price: {
            monthly: 9,
            yearly: Math.round(9 * 12 * (1 - 0.12)),
        },
        features: [
            { text: "Backend & frontend access",tooltip: "Access WP Spotlight from both the backend and frontend" },
            { text: "Manager users from search", tooltip: "Quickly search and manage user profiles directly from search" },
            { text: "Manage plugins from search",tooltip: "Activate, deactivate, and update plugins without leaving search" },
            { text: "Manage content from search", tooltip: "Find and edit posts, pages, and custom content instantly." },
            { text: "Manage media from search", tooltip: "Upload, edit, and insert media files straight from search." },
            { text: "Multisite switching", tooltip: "Easily switch between sites in multisite setups." },
            { text: "Custom keyboard shortcuts ", tooltip: "Assign custom key binds with up to 4 key combinations." },
        ],
        btn: {
            text: "Get started",
            href: "/auth/sign-up?plan=pro",
            variant: "purple",
        },
    },
    {
        name: "Agency",
        info: "For large organizations and Agencies",
        price: {
            monthly: 49,
            yearly: Math.round(49 * 12 * (1 - 0.12)),
        },
        features: [
            { text: "Backend & frontend access",tooltip: "Access WP Spotlight from both the backend and frontend" },
            { text: "Manager users from search", tooltip: "Quickly search and manage user profiles directly from search" },
            { text: "Manage plugins from search",tooltip: "Activate, deactivate, and update plugins without leaving search" },
            { text: "Manage content from search", tooltip: "Find and edit posts, pages, and custom content instantly." },
            { text: "Manage media from search", tooltip: "Upload, edit, and insert media files straight from search." },
            { text: "Multisite switching", tooltip: "Easily switch between sites in multisite setups." },
            { text: "Custom keyboard shortcuts ", tooltip: "Assign custom key binds with up to 4 key combinations." },
        ],
        btn: {
            text: "Contact team",
            href: "/auth/sign-up?plan=business",
            variant: "default",
        },
    },
];

export const PRICING_FEATURES = [
    {
        text: "Shorten links",
        tooltip: "Create shortened links",
    },
    {
        text: "Track clicks",
        tooltip: "Track clicks on your links",
    },
    {
        text: "See top countries",
        tooltip: "See top countries where your links are clicked",
    },
    {
        text: "Upto 10 tags",
        tooltip: "Add upto 10 tags to your links",
    },
    {
        text: "Community support",
        tooltip: "Community support is available for free users",
    },
    {
        text: "Priority support",
        tooltip: "Get priority support from our team",
    },
    {
        text: "AI powered suggestions",
        tooltip: "Get AI powered suggestions for your links",
    },
];

export const WORKSPACE_LIMIT = 2;
